import { useModal as useNiceModal } from '@ebay/nice-modal-react'
import AlertModal from './AlertModal'
import BottomSheetModal from './BottomSheetModal'
import ConfirmModal from './ConfirmModal'
import FullScreenModal from './FullScreenModal'
import LoginPromotionModal from './LoginPromotionModal'

/**
 * nice-modal-react의 useModal을 커스텀 훅으로 감싸서 사용
 * Alert, Confirm, fullScreen 컴포넌트를 사용하여 각각의 모달을 띄울 수 있음
 * @example 각 모달.show() 시 전달하는 매개변수는 각 컴포넌트의 Props와 동일
 * const { alertModal, confirmModal, fullScreenModal } = useModal()
 *   alertModal.show({
 *   title: '타이틀',
 *   message: '일반적 안내 메시지 / 설명',
 *   confirmButton: {
 *     text: '버튼명',
 *     onClick: () => console.log('alert  click.'),
 *   },
 *  })
 */
export const useModal = () => {
  const bottomSheetModal = useNiceModal(BottomSheetModal)
  const alertModal = useNiceModal(AlertModal)
  const confirmModal = useNiceModal(ConfirmModal)
  const fullScreenModal = useNiceModal(FullScreenModal)
  const loginPromotionModal = useNiceModal(LoginPromotionModal)
  const isModalOpen =
    alertModal.visible ||
    confirmModal.visible ||
    fullScreenModal.visible ||
    loginPromotionModal.visible ||
    bottomSheetModal.visible

  return {
    isModalOpen,
    alertModal,
    confirmModal,
    fullScreenModal,
    loginPromotionModal,
    bottomSheetModal,
  }
}
